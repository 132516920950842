const config = {
  dev: {
    cognito: {
      USER_POOL_ID:
        process.env.REACT_APP_COGNITO_POOL_ID || "us-east-2_yD5dcBiV7",
      APP_CLIENT_ID:
        process.env.REACT_APP_COGNITO_CLIENT_ID || "e0p5q7ef4b2jgirpja657hkdu",
    },
    api: {
      USER: "https://api.dev.thinkhomewise.com/partner/user",
      LOGO_UPLOAD: "https://api.dev.thinkhomewise.com/partner/logo",
      PARTNER: "https://api.dev.thinkhomewise.com/partner/brand",
    },
    google: {
      CAPTCHA: "6Lf5QtQUAAAAABY8kRzWmZcg1s8lGiceF_rr58kg",
      MAPS: "AIzaSyCpfvSXvFmRVugfe2DPWqTP8RPjowAfgpc",
      GTM_ID: "GTM-W5JRM7F",
      GTM_AUTH: "j30ztWjLrOb0cbp4WdS4JQ",
      GTM_PREV: "env-3",
    },
  },
  staging: {
    cognito: {
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    api: {
      USER: "https://api.staging.thinkhomewise.com/partner/user",
      LOGO_UPLOAD: "https://api.staging.thinkhomewise.com/partner/logo",
      PARTNER: "https://api.staging.thinkhomewise.com/partner/brand",
    },
    google: {
      CAPTCHA: "6Lf5QtQUAAAAABY8kRzWmZcg1s8lGiceF_rr58kg",
      MAPS: "AIzaSyAOt82efGsGnQggddjMLWzXORr0qeGPzrs",
      GTM_ID: "GTM-W5JRM7F",
      GTM_AUTH: "d40QuDGH0Y16CvaUncpCZA",
      GTM_PREV: "env-4",
    },
  },
  prod: {
    cognito: {
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    api: {
      USER: "https://api.thinkhomewise.com/partner/user",
      LOGO_UPLOAD: "https://api.thinkhomewise.com/partner/logo",
      PARTNER: "https://api.thinkhomewise.com/partner/brand",
    },
    google: {
      CAPTCHA: "6Lf5QtQUAAAAABY8kRzWmZcg1s8lGiceF_rr58kg",
      MAPS: "AIzaSyD-SogqecrmmQ_jPaDFW1_ruCJRYpADwG8",
      GTM_ID: "GTM-W5JRM7F",
      GTM_AUTH: "Z_uX9D7NmzC_41Rrp-tNrA",
      GTM_PREV: "env-1",
    },
  },
};

export default {
  // Add common config values here
  ...config[process.env.REACT_APP_STAGE],
};
